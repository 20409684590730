<template>
  <div class="sys-page flex-col">
    <div class="sys-layer1 flex-col">
      <div class="sys-wrap1 flex-col">
        <div class="sys-outer1 flex-col">
          <headInfo :type="type"></headInfo>
          <span class="sys-word3">一站式服务</span>
          <span class="sys-info5">为企业提供简洁高效的解决方案</span>
          <!-- <div class="sys-block2 flex-col"  @click="toNewPage(5)">
            <span class="sys-txt1">立即试用</span>
          </div> -->
        </div>
        <div class="sys-outer3 flex-col">
          <div class="sys-mod1 flex-col"></div>
        </div>
      </div>
      <span class="sys-txt2">企业服务</span>
      <span class="sys-word4"
        >客户至上&nbsp;&nbsp;&nbsp;陪伴客户规模化发展</span
      >
      <div class="sys-wrap2 flex-row">
        <div class="sys-main2 flex-col"></div>
        <img
          class="sys-img6"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/servertwo.png"
        />
        <div class="sys-main3 flex-col"></div>
        <div class="sys-main4 flex-col"></div>
      </div>
      <div class="sys-wrap3 flex-row">
        <span class="sys-info6">专业团队</span>
        <span class="sys-txt3">全流程</span>
        <span class="sys-txt4">强售后</span>
        <span class="sys-word5">定制化</span>
      </div>
      <div class="sys-wrap4 flex-row justify-between">
        <span class="sys-word6"
          >商务、售前、产品、研发、售后、客服，六大团队支撑</span
        >
        <span class="sys-word7">从售前到售后，全流程支撑，24*7为您服务</span>
        <span class="sys-info7">随时随地解决问题，定时培训，为您解忧答疑</span>
        <span class="sys-word8"
          >满足私有化部署以及定制化功能开发，打造专属您的系统</span
        >
      </div>
      <span class="sys-word9">全流程保障</span>
      <div class="sys-wrap5 flex-row">
        <img
          class="sys-img2"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/proone.png"
        />
        <img
          class="sys-label1"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/yellowright.png"
        />
        <img
          class="sys-pic2"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/protwo.png"
        />
        <img
          class="sys-label2"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/yellowright.png"
        />
        <img
          class="sys-pic3"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/prothird.png"
        />
      </div>
      <div class="sys-wrap6 flex-row justify-between">
        <span class="sys-info8">前期调研</span>
        <span class="sys-word10">方案设计</span>
        <span class="sys-word11">上线准备</span>
      </div>
      <div class="sys-wrap7 flex-row justify-between">
        <span class="sys-infoBox1">客户需求沟通<br />现场业务了解梳理</span>
        <span class="sys-infoBox2"
          >量身打造实施方案<br />系统操作与人员优化</span
        >
        <span class="sys-paragraph1"
          >基础数据初始化准备<br />系统整理与规划</span
        >
      </div>
      <div class="sys-wrap8 flex-row">
        <img
          class="sys-img3"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/profour.png"
        />
        <img
          class="sys-icon1"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/yellowleft.png"
        />
        <img
          class="sys-img4"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/profive.png"
        />
        <img
          class="sys-label3"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/yellowleft.png"
        />
        <img
          class="sys-pic4"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/prosix.png"
        />
      </div>
      <div class="sys-wrap9 flex-row justify-between">
        <span class="sys-word12">售后跟踪</span>
        <span class="sys-txt5">系统培训</span>
        <span class="sys-word13">专业实施</span>
      </div>
      <div class="sys-wrap10 flex-row justify-between">
        <span class="sys-paragraph2"
          >线上线下&nbsp;定期回访<br />管家式跟踪服务快速响应</span
        >
        <span class="sys-paragraph3"
          >点对点和重点岗位培训<br />协助角色权限分配</span
        >
        <span class="sys-infoBox3"
          >项目经理全程跟踪进度<br />确保系统切换无忧</span
        >
      </div>
      <div class="sys-wrap11 flex-col">
        <div class="sys-group5 flex-col">
          <span class="sys-txt6">管家式服务保障</span>
          <div class="sys-block3 flex-col"></div>
          <div class="sys-block4 flex-row">
            <div
              class="sys-main1 flex-col"
              v-for="(item, index) in loopData0"
              :key="index"
            >
              <div class="sys-main1 flex-col justify-between">
                <img
                  class="sys-img7"
                  referrerpolicy="no-referrer"
                  :src="item.lanhuimage0"
                />
                <span class="sys-info9" v-html="item.lanhutext0"></span>
              </div>
            </div>
          </div>
        </div>
        <!-- <img
          class="sys-img5"
          referrerpolicy="no-referrer"
          src="@/assets/main/guangdong.png"
        /> -->
      </div>
      <footInfo style="position: relative;"/>
      <!-- <div class="sys-wrap12 flex-col">
        <div class="sys-layer2 flex-col">
          <div class="sys-outer4 flex-row">
            <span class="sys-info10">产品</span> <span class="sys-word14">方案</span>
            <span class="sys-info11">服务</span> <span class="sys-word15">案例</span>
            <span class="sys-txt7">联系我们</span>
          </div>
          <div class="sys-outer5 flex-row">
            <div class="sys-wrap13 flex-col justify-between">
              <span class="sys-txt8">松云WMS</span>
              <span class="sys-word16">松云ERP</span>
            </div>
            <div class="sys-wrap14 flex-col justify-between">
              <span class="sys-word17">线上电商</span>
              <span class="sys-txt9">智能立库</span>
            </div>
            <span class="sys-info12">服务体系</span>
            <span class="sys-info13">客户案例</span>
            <span class="sys-word18">联系电话：020&nbsp;-&nbsp;85596056</span>
          </div>
          <div class="sys-outer6 flex-row">
            <div class="sys-mod2 flex-col justify-between">
              <span class="sys-word19">松云CRM</span>
              <span class="sys-word20">松云MALL</span>
              <span class="sys-txt10">松云TMS</span>
            </div>
            <div class="sys-mod3 flex-col justify-between">
              <span class="sys-word21">营销获客</span>
              <span class="sys-txt11">跨境运输</span>
            </div>
            <div class="sys-mod4 flex-col justify-between">
              <span class="sys-word22"
                >联系地址：天河北路365号寰城中心广场2103</span
              >
              <span class="sys-word23">邮箱：support&#64;syinov.com</span>
            </div>
          </div>
          <span class="sys-word24">松云SCM</span>
          <span class="sys-txt12"
            >如果你有任何问题，我们将为您解答&nbsp;如果你需要任何帮助，我们将提供协助&nbsp;请随时联系我们</span
          >
          <div class="sys-outer7 flex-col"></div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 2,
      loopData0: [
        {
          lanhuimage0:
            require('../../assets/images/procedure/ensureone.png'),
          lanhutext0: "每月定期线上产品培训",
        },
        {
          lanhuimage0:
            require('../../assets/images/procedure/ensuretwo.png'),
          lanhutext0: "求工单48小时内响应",
        },
        {
          lanhuimage0:
            require('../../assets/images/procedure/ensurethird.png'),
          lanhutext0: "专业本地团队上门实施指导",
        },
        {
          lanhuimage0:
            require('../../assets/images/procedure/ensurefour.png'),
          lanhutext0: "定期本地用户线下培训、沙龙",
        },
        {
          lanhuimage0:
            require('../../assets/images/procedure/ensurefive.png'),
          lanhutext0: "7*12h专业在线客户响应支持",
        },
        {
          lanhuimage0:
            require('../../assets/images/procedure/ensuresix.png'),
          lanhutext0: "双十一72小时全程保障",
        },
      ],
      constants: {},
    };
  },
  methods: {
    onClick_1() {
      alert(1);
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        case 1: // 产品介绍
          url = "/product/erp";
          break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance/erp";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" >
.sys-page {
  position: relative;
  width: 1920px;
  // height: 4571px;
  text-align: center;
  margin: 0 auto;
  background-color: rgba(250, 250, 250, 1);
  overflow: hidden;
  .sys-layer1 {
    width: 1920px;
    // height: 4571px;
    .sys-wrap1 {
      // z-index: 30;
      height: 920px;
      overflow: hidden;
      // background: url(../../assets/images/back/grayrec.png)
      //   100% no-repeat;
      width: 1920px;
      position: relative;
      .sys-outer1 {
        z-index: 60;
        width: 1920px;
        height: 610px;
        .sys-block1 {
          height: 100px;
          width: 1920px;
          .sys-outer2 {
            width: 1775px;
            height: 42px;
            margin: 29px 0 0 65px;
            .sys-img1 {
              width: 180px;
              height: 38px;
              margin-top: 2px;
            }
            .sys-info1 {
              width: 43px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 301px;
            }
            .sys-info2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .sys-word1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiB;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .sys-info3 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .sys-info4 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .sys-group1 {
              height: 42px;
              border-radius: 21px;
              border: 2px solid rgba(37, 184, 47, 1);
              margin-left: 346px;
              width: 149px;
              .sys-word2 {
                width: 85px;
                height: 29px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 0.94);
                font-size: 21px;
                letter-spacing: 0.41999998688697815px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 29px;
                text-align: left;
                margin: 7px 0 0 32px;
              }
            }
          }
        }
        .sys-word3 {
          width: 262px;
          height: 65px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 52px;
          letter-spacing: 1.0399999618530273px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 65px;
          text-align: center;
          margin: 221px 0 0 829px;
        }
        .sys-info5 {
          width: 411px;
          height: 52px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 28px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 52px;
          text-align: right;
          margin: 36px 0 0 755px;
        }
        .sys-block2 {
          height: 68px;
          border-radius: 34px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.32);
          width: 220px;
          margin: 68px 0 0 850px;
          .sys-txt1 {
            width: 113px;
            height: 38px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 28px;
            letter-spacing: 0.5600000023841858px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 38px;
            text-align: left;
            margin: 15px 0 0 54px;
          }
        }
      }
      .sys-outer3 {
        z-index: 31;
        height: 920px;
        background: url(../../assets/main/server.png)
          100% no-repeat;
        width: 1920px;
        position: absolute;
        left: 0;
        top: 0;
        .sys-mod1 {
          width: 1920px;
          height: 920px;
          // background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .sys-txt2 {
      width: 129px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(39, 39, 39, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 896px;
    }
    .sys-word4 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .sys-wrap2 {
      width: 1202px;
      height: 114px;
      margin: 75px 0 0 358px;
      .sys-main2 {
        width: 126px;
        height: 114px;
        background: url(../../assets/images/procedure/serverone.png)
          0px -1px no-repeat;
      }
      .sys-img6 {
        width: 108px;
        height: 108px;
        margin: 3px 0 0 242px;
      }
      .sys-main3 {
        width: 122px;
        height: 110px;
        background: url(../../assets/images/procedure/serverthird.png)
          0px -2px no-repeat;
        margin: 2px 0 0 246px;
      }
      .sys-main4 {
        width: 120px;
        height: 113px;
        background: url(../../assets/images/procedure/serverfour.png) -1px -2px
          no-repeat;
        margin: 1px 0 0 238px;
      }
    }
    .sys-wrap3 {
      width: 1155px;
      height: 29px;
      margin: 43px 0 0 377px;
      .sys-info6 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
      .sys-txt3 {
        width: 64px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
        margin-left: 286px;
      }
      .sys-txt4 {
        width: 64px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: center;
        margin-left: 296px;
      }
      .sys-word5 {
        width: 64px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
        margin-left: 296px;
      }
    }
    .sys-wrap4 {
      width: 1325px;
      height: 56px;
      margin: 16px 0 0 297px;
      .sys-word6 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sys-word7 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sys-info7 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sys-word8 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .sys-word9 {
      width: 161px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 880px;
    }
    .sys-wrap5 {
      width: 1236px;
      height: 264px;
      margin: 34px 0 0 342px;
      .sys-img2 {
        width: 340px;
        height: 264px;
      }
      .sys-label1 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 28px;
      }
      .sys-pic2 {
        width: 340px;
        height: 264px;
        margin-left: 34px;
      }
      .sys-label2 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 34px;
      }
      .sys-pic3 {
        width: 340px;
        height: 264px;
        margin-left: 28px;
      }
    }
    .sys-wrap6 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .sys-info8 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .sys-word10 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .sys-word11 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .sys-wrap7 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .sys-infoBox1 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sys-infoBox2 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sys-paragraph1 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .sys-wrap8 {
      width: 1236px;
      height: 264px;
      margin: 40px 0 0 342px;
      .sys-img3 {
        width: 340px;
        height: 264px;
      }
      .sys-icon1 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 28px;
      }
      .sys-img4 {
        width: 340px;
        height: 264px;
        margin-left: 34px;
      }
      .sys-label3 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 34px;
      }
      .sys-pic4 {
        width: 340px;
        height: 264px;
        margin-left: 28px;
      }
    }
    .sys-wrap9 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .sys-word12 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .sys-txt5 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .sys-word13 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .sys-wrap10 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .sys-paragraph2 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sys-paragraph3 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .sys-infoBox3 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .sys-wrap11 {
      height: 728px;
      background-color: rgba(37, 184, 47, 1);
      margin-top: 120px;
      width: 1920px;
      .sys-group5 {
        width: 1014px;
        height: 560px;
        margin: 80px 0 0 449px;
        .sys-txt6 {
          width: 225px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 32px;
          letter-spacing: 0.6399999856948853px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 44px;
          text-align: left;
          margin-left: 398px;
        }
        .sys-block3 {
          width: 84px;
          height: 4px;
          background-color: rgba(255, 255, 255, 1);
          margin: 12px 0 0 469px;
        }
        .sys-block4 {
          width: 1014px;
          height: 422px;
          margin-top: 78px;
          flex-wrap: wrap;
          .sys-main1 {
            width: 171px;
            height: 182px;
            margin: 0 236px 58px 0;
            &:nth-child(3n) {
              margin-right: 0;
            }
            &:nth-last-child(-n + 3) {
              margin-bottom: 0;
            }
            .sys-main1 {
              width: 171px;
              height: 182px;
              margin: 0 236px 58px 0;
              &:nth-child(3n) {
                margin-right: 0;
              }
              &:nth-last-child(-n + 3) {
                margin-bottom: 0;
              }
              .sys-img7 {
                width: 128px;
                height: 128px;
                margin-left: 22px;
              }
              .sys-info9 {
                width: 223px;
                height: 28px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiM;
                white-space: nowrap;
                line-height: 28px;
                text-align: center;
                margin-top: 26px;
              }
            }
          }
        }
      }
    }
    .sys-wrap12 {
      height: 527px;
      background-color: rgba(38, 38, 38, 1);
      margin-top: 799px;
      width: 1920px;
      .sys-layer2 {
        width: 1520px;
        height: 362px;
        margin: 81px 0 0 201px;
        .sys-outer4 {
          width: 1015px;
          height: 36px;
          margin-left: 128px;
          .sys-info10 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .sys-word14 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 156px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .sys-info11 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .sys-word15 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .sys-txt7 {
            width: 87px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 1px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 36px;
            text-align: left;
            margin-left: 388px;
          }
        }
        .sys-outer5 {
          width: 1138px;
          height: 56px;
          margin: 20px 0 0 128px;
          .sys-wrap13 {
            width: 73px;
            height: 56px;
            .sys-txt8 {
              width: 73px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .sys-word16 {
              width: 64px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .sys-wrap14 {
            width: 67px;
            height: 56px;
            margin-left: 121px;
            .sys-word17 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .sys-txt9 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .sys-info12 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .sys-info13 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .sys-word18 {
            width: 210px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 16px 0 0 359px;
          }
        }
        .sys-outer6 {
          width: 1262px;
          height: 88px;
          margin: 8px 0 0 128px;
          .sys-mod2 {
            width: 77px;
            height: 88px;
            .sys-word19 {
              width: 68px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .sys-word20 {
              width: 77px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
            .sys-txt10 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .sys-mod3 {
            width: 67px;
            height: 56px;
            margin-left: 117px;
            .sys-word21 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .sys-txt11 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .sys-mod4 {
            width: 334px;
            height: 84px;
            margin-left: 667px;
            .sys-word22 {
              width: 334px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .sys-word23 {
              width: 220px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .sys-word24 {
          width: 68px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 0.761904776096344px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 8px 0 0 128px;
        }
        .sys-txt12 {
          width: 632px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 0.8);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 81px 0 0 443px;
        }
        .sys-outer7 {
          width: 1520px;
          height: 1px;
          background: url(../../assets/images/back/line.png)
            0px 0px no-repeat;
          margin-top: 16px;
        }
      }
    }
  }
  .sys-img5 {
    // z-index: 190;
    position: absolute;
    left: 0;
    top: 3244px;
    width: 1920px;
    height: 800px;
  }
}
</style>
<style src="../../assets/css/common.css" />
