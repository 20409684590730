var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sys-page flex-col"},[_c('div',{staticClass:"sys-layer1 flex-col"},[_c('div',{staticClass:"sys-wrap1 flex-col"},[_c('div',{staticClass:"sys-outer1 flex-col"},[_c('headInfo',{attrs:{"type":_vm.type}}),_c('span',{staticClass:"sys-word3"},[_vm._v("一站式服务")]),_c('span',{staticClass:"sys-info5"},[_vm._v("为企业提供简洁高效的解决方案")])],1),_vm._m(0)]),_c('span',{staticClass:"sys-txt2"},[_vm._v("企业服务")]),_c('span',{staticClass:"sys-word4"},[_vm._v("客户至上   陪伴客户规模化发展")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('span',{staticClass:"sys-word9"},[_vm._v("全流程保障")]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('div',{staticClass:"sys-wrap11 flex-col"},[_c('div',{staticClass:"sys-group5 flex-col"},[_c('span',{staticClass:"sys-txt6"},[_vm._v("管家式服务保障")]),_c('div',{staticClass:"sys-block3 flex-col"}),_c('div',{staticClass:"sys-block4 flex-row"},_vm._l((_vm.loopData0),function(item,index){return _c('div',{key:index,staticClass:"sys-main1 flex-col"},[_c('div',{staticClass:"sys-main1 flex-col justify-between"},[_c('img',{staticClass:"sys-img7",attrs:{"referrerpolicy":"no-referrer","src":item.lanhuimage0}}),_c('span',{staticClass:"sys-info9",domProps:{"innerHTML":_vm._s(item.lanhutext0)}})])])}),0)])]),_c('footInfo',{staticStyle:{"position":"relative"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sys-outer3 flex-col"},[_c('div',{staticClass:"sys-mod1 flex-col"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sys-wrap2 flex-row"},[_c('div',{staticClass:"sys-main2 flex-col"}),_c('img',{staticClass:"sys-img6",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/servertwo.png")}}),_c('div',{staticClass:"sys-main3 flex-col"}),_c('div',{staticClass:"sys-main4 flex-col"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sys-wrap3 flex-row"},[_c('span',{staticClass:"sys-info6"},[_vm._v("专业团队")]),_c('span',{staticClass:"sys-txt3"},[_vm._v("全流程")]),_c('span',{staticClass:"sys-txt4"},[_vm._v("强售后")]),_c('span',{staticClass:"sys-word5"},[_vm._v("定制化")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sys-wrap4 flex-row justify-between"},[_c('span',{staticClass:"sys-word6"},[_vm._v("商务、售前、产品、研发、售后、客服，六大团队支撑")]),_c('span',{staticClass:"sys-word7"},[_vm._v("从售前到售后，全流程支撑，24*7为您服务")]),_c('span',{staticClass:"sys-info7"},[_vm._v("随时随地解决问题，定时培训，为您解忧答疑")]),_c('span',{staticClass:"sys-word8"},[_vm._v("满足私有化部署以及定制化功能开发，打造专属您的系统")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sys-wrap5 flex-row"},[_c('img',{staticClass:"sys-img2",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/proone.png")}}),_c('img',{staticClass:"sys-label1",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/yellowright.png")}}),_c('img',{staticClass:"sys-pic2",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/protwo.png")}}),_c('img',{staticClass:"sys-label2",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/yellowright.png")}}),_c('img',{staticClass:"sys-pic3",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/prothird.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sys-wrap6 flex-row justify-between"},[_c('span',{staticClass:"sys-info8"},[_vm._v("前期调研")]),_c('span',{staticClass:"sys-word10"},[_vm._v("方案设计")]),_c('span',{staticClass:"sys-word11"},[_vm._v("上线准备")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sys-wrap7 flex-row justify-between"},[_c('span',{staticClass:"sys-infoBox1"},[_vm._v("客户需求沟通"),_c('br'),_vm._v("现场业务了解梳理")]),_c('span',{staticClass:"sys-infoBox2"},[_vm._v("量身打造实施方案"),_c('br'),_vm._v("系统操作与人员优化")]),_c('span',{staticClass:"sys-paragraph1"},[_vm._v("基础数据初始化准备"),_c('br'),_vm._v("系统整理与规划")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sys-wrap8 flex-row"},[_c('img',{staticClass:"sys-img3",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/profour.png")}}),_c('img',{staticClass:"sys-icon1",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/yellowleft.png")}}),_c('img',{staticClass:"sys-img4",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/profive.png")}}),_c('img',{staticClass:"sys-label3",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/yellowleft.png")}}),_c('img',{staticClass:"sys-pic4",attrs:{"referrerpolicy":"no-referrer","src":require("@/assets/images/procedure/prosix.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sys-wrap9 flex-row justify-between"},[_c('span',{staticClass:"sys-word12"},[_vm._v("售后跟踪")]),_c('span',{staticClass:"sys-txt5"},[_vm._v("系统培训")]),_c('span',{staticClass:"sys-word13"},[_vm._v("专业实施")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sys-wrap10 flex-row justify-between"},[_c('span',{staticClass:"sys-paragraph2"},[_vm._v("线上线下 定期回访"),_c('br'),_vm._v("管家式跟踪服务快速响应")]),_c('span',{staticClass:"sys-paragraph3"},[_vm._v("点对点和重点岗位培训"),_c('br'),_vm._v("协助角色权限分配")]),_c('span',{staticClass:"sys-infoBox3"},[_vm._v("项目经理全程跟踪进度"),_c('br'),_vm._v("确保系统切换无忧")])])
}]

export { render, staticRenderFns }